<template>
    <b-modal
        :id="id"
        body-class="edit-modal"
        :ok-disabled="busy || disabled"
        :cancel-disabled="busy"
        :size="size"
        @ok="ok"
        @hidden="hidden"
    >
        <slot></slot>
        <template #modal-title>
            <template v-if="mode == 'create'">
                Neuen Datensatz hinzufügen
            </template>
            <template v-else-if="mode == 'edit'">
                Datensatz bearbeiten
            </template>
            <template v-else>
                {{ title }}
            </template>
        </template>

        <template #modal-ok>
            <Spinner v-if="busy" small variant="light" />
            {{ okButton }}
        </template>
        <template #modal-cancel>Abbrechen</template>
    </b-modal>
</template>

<script>
import Spinner from "@/components/Spinner/Spinner";

import "./EditModal.scss";

export default {
    name: "EditModal",
    components: {
        Spinner
    },
    props: {
        id: {
            type: String,
            default: "edit-modal"
        },
        busy: {
            type: Boolean,
            default: false
        },
        mode: {
            type: String,
            default: "edit"
        },
        title: {
            type: String,
            default: "Bearbeiten"
        },
        size: {
            type: String,
            default: "md"
        },
        okButton: {
            type: String,
            default: "Speichern"
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ok(event) {
            this.$emit("ok", event);
        },
        hidden(event) {
            this.$emit("hidden", event);
        }
    }
};
</script>
